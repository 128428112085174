import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import "../../css/OtherStyles/navbar.css";
import "../../css/General.css";
import nav_logo from "../../img/logo-2.png";

const Navbar = () => {
  const headerRef = useRef();

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleCloseNavExpanded = () => {
    setTimeout(() => {
      setIsNavExpanded(!isNavExpanded);
      handleCloseNav();
    }, 100);
  };

  const handleOpenNav = () => {
    headerRef.current.classList.add("nav-open");
    document.querySelector("html").style.overflow = "hidden";
  };
  const handleCloseNav = () => {
    headerRef.current.classList.remove("nav-open");
    document.querySelector("html").style.overflow = "auto";
  };

  return (
    <>
      <header
        id="header"
        className={`header poppins_font_style p-2 bg-white backdrop-blur-sm sticky top-0 z-50`}
      >
        <a href="/#home" className="main_nav_logo flex-shrink-0">
          <img src={nav_logo} className="nav_logo" alt="Nav Logo" />
        </a>

        <nav
          ref={headerRef}
          className={`main_nav flex justify-between items-center flex-row`}
        >
          <div>
            <ul className="main_nav_ul text-base font-medium text-slate-700 flex justify-between items-center flex-row">
              <li>
                <Link
                  id="main_nav_menu_link_home"
                  onClick={() => handleCloseNavExpanded()}
                  to="/"
                  className="main_nav_link text-lg px-4 py-1 rounded-md text-black"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  id="main_nav_menu_link_team"
                  to="/team"
                  onClick={() => handleCloseNavExpanded()}
                  className="main_nav_link text-lg px-4 py-1 rounded-md text-black"
                >
                  Team
                </Link>
              </li>
              <li>
                <Link
                  id="main_nav_menu_link_contact"
                  to="/contact"
                  onClick={() => handleCloseNavExpanded()}
                  className="main_nav_link text-lg px-4 py-1 rounded-md text-black"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  id="main_nav_menu_link_sci"
                  to="/science"
                  onClick={() => handleCloseNavExpanded()}
                  className="main_nav_link text-lg px-4 py-1 rounded-md text-black"
                >
                  Science
                </Link>
              </li>
              <li>
                <Link
                  id="main_nav_menu_link_blogs"
                  to="/blogs"
                  onClick={() => handleCloseNavExpanded()}
                  className="main_nav_link text-lg px-4 py-1 rounded-md text-black"
                >
                  Blogs
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <button
          className="btn_mobile_nav"
          href="/"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <ion-icon
            className="icon_mobile_nav"
            name="menu-outline"
            style={{ display: isNavExpanded ? "none" : "inline-block" }}
            onClick={handleOpenNav}
          ></ion-icon>
          <ion-icon
            className="icon_mobile_nav"
            name="close-outline"
            style={{ display: !isNavExpanded ? "none" : "inline-block" }}
            onClick={handleCloseNav}
          ></ion-icon>
        </button>
      </header>
    </>
  );
};

export default Navbar;
