import React from "react";

import "../../css/HomeStyles/Download.css";
import iphone_1 from "../../img/home/download/iphone-1.png";
import iphone_2 from "../../img/home/download/iphone-2.png";
// import google_play_btn from "../../img/svg/google_play.svg";
import apple_store_btn from "../../img/svg/apple_download_btn.svg";

const Download = () => {
  return (
    <>
      <section className="section_downld">
        <div className="container">
          <div className="main_container_downld w-full flex flex-row justify-center items-center gap-8">
            {/* Data Div */}
            <div className="data_div_downld w-full flex flex-col justify-center items-center gap-11">
              {/* Heading */}
              <div className="heading_div_downld flex justify-start items-center">
                <h2 className="primary_heading">Download the app today</h2>
              </div>

              {/* Text */}
              {/* <div className="text_div_downld flex flex-col justify-center items-start gap-4">
                <p className="w-full primary_text">
                  Understand your stress and live a more mindful life with
                  MindGinie. Enjoy a free trial.
                </p>
              </div> */}

              {/* Google Play Btn */}
              {/* <a
                id="hero_google_play_btn"
                className="img_div_downld w-full flex justify-start items-center"
                href="https://play.google.com/store/search?q=mindginie&c=apps&hl=en-IN"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="signUp_iOS_store_btn"
                  src={google_play_btn}
                  alt="Google Play button"
                />
              </a> */}

              {/* App Store Btn */}
              <a
                id="hero_app_store_btn"
                className="img_div_downld app_store_link w-full flex justify-start items-center"
                href="https://apps.apple.com/in/app/mindginie/id6462723261"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="signUp_iOS_store_btn"
                  src={apple_store_btn}
                  alt="App Store button"
                />
              </a>
            </div>

            {/* Image Div - mt-56 & mb-56 were given */}
            <div className="image_div_downld flex flex-row justify-between">
              {/* Image 1 */}
              <div className="image_1_downld overflow-hidden flex justify-center items-end">
                <img src={iphone_1} alt="Phone with Mindginie icon" />
              </div>

              {/* Image 2 */}
              <div className="image_2_downld overflow-hidden flex justify-center items-start">
                <img src={iphone_2} alt="Phone with Mindginie notification" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
