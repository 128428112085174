import { React, useState } from "react";
import "../../css/TeamStyles/Team.css";

const TeamCaraousel = ({ images }) => {
  const [current, setCurrent] = useState(0);

  const slideLeft = () => {
    setCurrent(current === 0 ? images.length - 1 : current - 1);
  };

  const slideRight = () => {
    setCurrent(current === images.length - 1 ? 0 : current + 1);
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="carousel">
          <div className="carousel_wrapper">
            {images.map((image, index) => {
              return (
                <div
                  key={index}
                  className={
                    index === current
                      ? "carousel_card carousel_card-active"
                      : "carousel_card"
                  }
                >
                  <img className="cards_image" src={image.image} alt="abc" />
                  <div className="card_overlay">
                    <h2 className="card_title card_title_name flex justify-center items-center">
                      {image.title}
                    </h2>
                    <h2 className="card_title card_title_about flex justify-center items-center">
                      {image.about}
                    </h2>
                  </div>
                </div>
              );
            })}
            <div className="carousel_arrow_left" onClick={slideLeft}>
              &lsaquo;
            </div>
            <div className="carousel_arrow_right" onClick={slideRight}>
              &rsaquo;
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCaraousel;
