import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Footer from "./components/Footer/Footer";
import Team from "./pages/Team/Team";
import Contact from "./pages/Contact/Contact";

import Terms from "./pages/PrivacyPolicy/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Research from "./pages/Research/Research";

import "./css/App.css";
import "./css/General.css";

import Blogs from "./pages/Blogs/Blogs";
import Blog1 from "./pages/Blogs/Blog_pages/Blog1";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/team" element={<Team />} />
          <Route path="/footer" element={<Footer />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/science" element={<Research />} />

          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />

          <Route path="/blogs" element={<Blogs />} />
          <Route
            path="/blogs/psychological-stress-vs-physiological-stress"
            element={<Blog1 />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}
export default App;
