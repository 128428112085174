import React from "react";

import Hero from "../../components/HomeComponents/Hero";
import LearnMore from "../../components/HomeComponents/LearnMore";
import Wellness from "../../components/HomeComponents/Wellness";
import Download from "../../components/HomeComponents/Download";
import Personalise from "../../components/HomeComponents/Personalise";
import Understand from "../../components/HomeComponents/Understand";
import Footer from "../../components/Footer/Footer";

import "../../css/HomeStyles/Home.css";

const Home = () => {
  return (
    <>
      <main>
        <Hero />
        <LearnMore />
        <Wellness />
        <Download />
        <Personalise />
        <Understand />
        <Footer />
      </main>
    </>
  );
};

export default Home;
