import React from "react";

import "../../css/HomeStyles/Understand.css";
import understand_phone from "../../img/home/understand/understand_phone.png";
import udsCheckMark from "../../img/svg/udsCheckMark.svg";
// import udsCheckMark from "../../img/home/understand/udsCheckMark.png";

const Understand = () => {
  return (
    <>
      <section className="section_understand">
        <div className="container">
          <div className="main-container_uds">
            {/* Image Div */}
            <div className="main_img_div_uds">
              <img
                className="main_img_uds"
                src={understand_phone}
                alt="Understanding Analytics"
              />
            </div>

            {/* Points Div */}
            <div className="desc_div_uds">
              <div className="desc_uds">
                <div className="desc_head_div_uds">
                  <h2 className="desc_head_uds second_heading">
                    Let's Understand stress better
                  </h2>
                </div>

                <ul className="desc_ul_uds">
                  <li className="desc_li_uds">
                    <div className="desc_checkMark_div_uds">
                      <img
                        className="desc_checkMark_uds"
                        src={udsCheckMark}
                        alt="Check Mark"
                      />
                    </div>
                    <div className="desc_txt_div_uds">
                      <p className="desc_li_txt_uds primary_text_24px lato_font_style">
                        Real-time stress identification using
                        scientifically-proven methods
                      </p>
                    </div>
                  </li>
                  <li className="desc_li_uds">
                    <div className="desc_checkMark_div_uds">
                      <img
                        className="desc_checkMark_uds"
                        src={udsCheckMark}
                        alt="Check Mark"
                      />
                    </div>
                    <div className="desc_txt_div_uds">
                      <p className="desc_li_txt_uds primary_text_24px lato_font_style">
                        Differentiate between panic attacks, anxiety and daily
                        stress
                      </p>
                    </div>
                  </li>
                  <li className="desc_li_uds">
                    <div className="desc_checkMark_div_uds">
                      <img
                        className="desc_checkMark_uds"
                        src={udsCheckMark}
                        alt="Check Mark"
                      />
                    </div>
                    <div className="desc_txt_div_uds">
                      <p className="desc_li_txt_uds primary_text_24px lato_font_style">
                        Event-based personalised techniques to manage stress &
                        anxiety
                      </p>
                    </div>
                  </li>
                  <li className="desc_li_uds">
                    <div className="desc_checkMark_div_uds">
                      <img
                        className="desc_checkMark_uds"
                        src={udsCheckMark}
                        alt="Check Mark"
                      />
                    </div>
                    <div className="desc_txt_div_uds">
                      <p className="desc_li_txt_uds primary_text_24px lato_font_style">
                        Track stress indicators over time and view progress
                      </p>
                    </div>
                  </li>
                  <li className="desc_li_uds">
                    <div className="desc_checkMark_div_uds">
                      <img
                        className="desc_checkMark_uds"
                        src={udsCheckMark}
                        alt="Check Mark"
                      />
                    </div>
                    <div className="desc_txt_div_uds">
                      <p className="desc_li_txt_uds primary_text_24px lato_font_style">
                        Record stress manually to get quick solutions
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Understand;
