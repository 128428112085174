import { React } from "react";
import "../../css/TeamStyles/Team.css";
import TeamCaraousel from "../../components/TeamComponents/TeamCaraousel";
import { teamPics } from "../../components/TeamComponents/teamData";

const Team = () => {
  return (
    <>
      <section
        id="team"
        className="poppins_font_style section_team flex justify-center items-center"
      >
        <div className="team_page_center">
          <div className="team_page_container container ">
            <h1 className="second_heading team_main_heading flex justify-center items-center pb-8">
              Meet Our Team
            </h1>
            <div className="">
              <TeamCaraousel images={teamPics} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
