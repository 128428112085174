import React from "react";

import "../../css/HomeStyles/Wellness.css";
import days7 from "../../img/home/wellness/last-7-days.png";
import topTriggers from "../../img/home/wellness/top-triggers.png";
import physical_symptoms from "../../img/home/wellness/physical-symptoms.png";
import vitals from "../../img/home/wellness/vitals.png";
import arrow_right from "../../img/svg/arrow-right.svg";

const Wellness = () => {
  return (
    <>
      <section className="section_wellness">
        <div className="container">
          <div className="main-container_wll">
            <div className="prim-heading-div_wll">
              <h2 className="second_heading">
                Your All-In-One Wellness Partner
              </h2>
            </div>

            <div className="second-text-div_wll">
              <p className="primary_text_24px lato_font_style font-medium">
                Our data and AI centric product helps you manage stress &
                anxiety in minutes,
              </p>
              <p className="primary_text_24px lato_font_style font-medium">
                giving you personalised solutions for overall wellness.
              </p>
            </div>

            <div className="main-tile-container_wll">
              <div className="tile-container_wll">
                <div className="tile-box_wll tile-one_wll">
                  <div className="tile-box-A_wll">
                    <img src={days7} alt="7 days chart" />
                  </div>

                  <div className="tile-box-B_wll">
                    <h3 className="fifth_heading">
                      Track Stress, Anxiety & Panic Alerts
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      Get real-time alerts based on your vitals and track
                      improvements over time. Our AI-based algorithm will
                      categorise each alert as a stress, anxiety or panic
                      response.
                    </p>
                  </div>
                </div>

                <div className="tile-box_wll tile-two_wll">
                  <div className="tile-box-A_wll">
                    <img src={topTriggers} alt="7 days chart" />
                  </div>

                  <div className="tile-box-B_wll">
                    <h3 className="fifth_heading">
                      Understand Your Stress Triggers
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      Understand which specific events or actions trigger
                      stress, anxiety or panic responses in you, so that you
                      learn to manage and mitigate them better.
                    </p>
                  </div>
                </div>

                <div className="tile-box_wll tile-three_wll">
                  <div className="tile-box-A_wll">
                    <img src={physical_symptoms} alt="7 days chart" />
                  </div>

                  <div className="tile-box-B_wll">
                    <h3 className="fifth_heading">
                      Track your Physical Symptoms
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      Stress and anxiety often manifest in the form of physical
                      symptoms and health impact. We help you track your top
                      symptoms, categorised as per the nature of the issue.
                    </p>
                  </div>
                </div>

                <div className="tile-box_wll tile-four_wll">
                  <div className="tile-box-A_wll">
                    <img src={vitals} alt="7 days chart" />
                  </div>

                  <div className="tile-box-B_wll">
                    <h3 className="fifth_heading">
                      Stay On Top of Your Vitals
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      Track vitals that impact your mental and physical wellness
                      - including Heart Rate Variability, Breathing Rate and
                      Sleep Patterns.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cta_wll">
              <a
                className="circular_btns"
                href="https://apps.apple.com/in/app/mindginie/id6462723261"
                target="_blank"
                rel="noreferrer"
              >
                Get the App <img src={arrow_right} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Wellness;
