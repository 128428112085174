import Jessica_pic from "../../img/team/Jessica_Photo.jpg";
import Abhijit_pic from "../../img/team/Abhijit_Photo.jpg";
import DrPubali_pic from "../../img/team/Dr_Pubali_Photo.jpg";
import Jayant_pic from "../../img/team/Jayant_Photo.jpg";
import Supriya_pic from "../../img/team/Supriya_Rao.jpg";

export const teamPics = [
  {
    image: Jessica_pic,
    title: "Jessica Dawda",
    about: "Founder & CEO",
  },
  {
    image: Abhijit_pic,
    title: "Abhijit Nayak",
    about: "VP-Product",
  },
  {
    image: DrPubali_pic,
    title: "Dr. Pubali Chaudhuri",
    about: "Neuro-Psychiatrist",
  },
  {
    image: Jayant_pic,
    title: "Jayant Arora",
    about: "Software Developer",
  },
  {
    image: Supriya_pic,
    title: "Supriya Rao",
    about: "Breathe Work Specialist",
  },
];
