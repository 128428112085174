import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import "../../css/HomeStyles/TrialForm.css";
import "../../css/ContactStyles/Contact.css";
import success_img from "../../img/home/trial_form/trial_form_success.png";

const Contact = () => {
  const [email, setEmail] = useState("");
  const [headingTagDelete, setHeadingTagDelete] = useState(true);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  const handleDeleteForm = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmitDelete = async (e) => {
    e.preventDefault();

    // Testing form functioning
    // if (email === "abc@abc") {
    //   setHeadingTagDelete(false);

    //   let setHeading = setInterval(() => {
    //     setHeadingTagDelete(true);
    //     handleNavigate();
    //     clearInterval(setHeading);
    //   }, 8000);
    // } else {
    //   console.log("This email has already been registered!");
    // }

    try {
      const res = await axios.post(
        "https://apis.mindginie.com/accounts/pre-register/",
        {
          email,
        }
      );

      if (res.data.status === true) {
        // console.log(res.data);
        setHeadingTagDelete(false);

        let setHeading = setInterval(() => {
          setHeadingTagDelete(true);
          handleNavigate();
          clearInterval(setHeading);
        }, 8000);
      } else {
        console.log("This email has already been processed for deletion!");
      }
    } catch (e) {
      console.log(e);
    }

    setEmail("");
  };

  return (
    <>
      <section id="contact" className="section_contact poppins_font_style  ">
        <div className="contact_div_container">
          <div className="contact_div_2 flex justify-center items-center">
            <div className="contact_div mx-auto p-8 flex flex-col">
              <div className="contact_main_heading_div flex justify-center items-center flex-col">
                <h2 className="second_heading contact_main_heading">
                  <span>Contact Us</span>
                </h2>
              </div>
              <div className="contact_main_text_div mx-auto break-words flex justify-center items-center flex-col">
                <p className="second_text contact_div_text">
                  <span>We would love to hear your thoughts</span>
                </p>
                <p className="second_text contact_div_text">
                  You can also reach out to us directly on{" "}
                  <a
                    id="contact_us_support_email"
                    className="contact_email_link"
                    href="mailto:support@mindginie.in"
                  >
                    support@mindginie.in
                  </a>
                </p>
              </div>

              <div className="mx-auto">
                <div className="mx-auto mb-4 break-words flex justify-center items-center flex-col">
                  {/* Delete Form */}
                  <form
                    id="user_delete_form"
                    onSubmit={handleSubmitDelete}
                    action=""
                    name="trial-form"
                  >
                    <div className="contact_form_fields_div">
                      {headingTagDelete ? (
                        <label
                          htmlFor="email"
                          className="email_label email_label_black poppins_font_style"
                        >
                          <p className="second_text contact_div_text">
                            <span>Want us to delete your account? </span>
                            <span>Request here -</span>
                          </p>
                        </label>
                      ) : (
                        <div className="email_label email_label_black poppins_font_style">
                          <h2
                            id="email_submit_acknowledge"
                            className="second_text"
                          >
                            Your account will be deleted in 5 working days.
                          </h2>
                        </div>
                      )}

                      {headingTagDelete ? (
                        <div className="email_inp_div_contct_frm">
                          <input
                            value={email}
                            onChange={handleDeleteForm}
                            type="email"
                            className="email_input second_text poppins_font_style"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            aria-describedby="email"
                            required
                          />

                          <div className="trial_form_submit_btn">
                            <button
                              id="hero_section_email_btn"
                              className="contact_del_form form_btn_trl_frm second_text"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      ) : (
                        <img
                          className="success_img_trl_frm"
                          src={success_img}
                          alt="Success"
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>

              <div className="mx-auto">
                <p className="second_text">Follow Us:</p>
              </div>
              <ul className="contact_logo_div mx-auto flex flex-row gap-4">
                <li className="text-4xl">
                  <a
                    className="contact_social_link"
                    href="https://www.instagram.com/mindginie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ion-icon
                      className="social_icon"
                      name="logo-instagram"
                    ></ion-icon>
                  </a>
                </li>
                <li className="text-4xl">
                  <a
                    className="contact_social_link"
                    href="https://www.linkedin.com/company/mindginie/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ion-icon
                      className="social_icon"
                      name="logo-linkedin"
                    ></ion-icon>
                  </a>
                </li>
                <li className="text-4xl">
                  <a
                    className="contact_social_link"
                    href="https://youtube.com/@mindginie"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ion-icon
                      className="social_icon"
                      name="logo-youtube"
                    ></ion-icon>
                  </a>
                </li>
              </ul>
              <div className="contact_link_div">
                <div className="footer_link">
                  <Link to="/terms" className="second_text footer_email_link">
                    Terms & Conditions
                  </Link>
                </div>

                <div className="contact_link">
                  <Link
                    to="/privacy-policy"
                    className="second_text footer_email_link"
                  >
                    Privacy Policy
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
