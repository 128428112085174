import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../../css/OtherStyles/Footer.css";
import ftr_logo from "../../img/logo-2.png";
import fb_logo from "../../img/svg/fb_logo_ftr.svg";
import insta_logo from "../../img/svg/insta_logo_ftr.svg";
import lnkd_logo from "../../img/svg/lnkd_logo_ftr.svg";
import yt_logo from "../../img/svg/yt_logo_ftr.svg";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [headingTag, setHeadingTag] = useState(true);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  // const handleTrialForm = (e) => {
  //   setEmail(e.target.value);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(email);

    try {
      const res = await axios.post(
        "https://apis.mindginie.com/accounts/pre-register/",
        {
          email,
        }
      );

      if (res.data.status === true) {
        console.log(res.data);

        setHeadingTag(false);

        let setHeading = setInterval(() => {
          setHeadingTag(true);
          handleNavigate();
          clearInterval(setHeading);
        }, 6000);
      } else {
        console.log("This email has already been registered!");
      }
    } catch (e) {
      console.log(e);
    }

    setEmail("");
  };

  return (
    <>
      <section id="footer" className="section_footer special_container">
        <div className="main_container_ftr">
          {/* Footer Div */}
          <div className="main_div_ftr">
            {/* Container - 1 */}
            <div className="container_ftr cont_1_ftr">
              {/* Cont - 1a */}
              <div className="cont_1a_ftr">
                <img
                  className="cont_1a_img_ftr w-24 ml-3"
                  src={ftr_logo}
                  alt="MindGinie Logo"
                />
              </div>

              {/* Cont - 1b */}
              <div className="cont_1b_ftr">
                <form
                  className="form_ftr"
                  onSubmit={handleSubmit}
                  name="trial-form"
                  action=""
                >
                  <div className="form_div_ftr">
                    {/* FORM LABEL */}
                    {headingTag ? (
                      <label htmlFor="email" className="form_label_ftr">
                        <span className="form_label_span_ftr fourth_text">
                          We have touched 5k+ lives to manage stress & anxiety.
                          Get your free trial now
                        </span>
                      </label>
                    ) : (
                      <div className="form_thankyou_span_ftr">
                        <span className="fourth_text">
                          Thank you for subscribing
                        </span>
                      </div>
                    )}

                    {headingTag ? (
                      <div className="form_cta_fields_ftr">
                        {/* FORM INPUT */}
                        {/* <div className="form_inp_div_ftr">
                          <input
                            className="form_inp_ftr fourth_text"
                            value={email}
                            onChange={handleTrialForm}
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Enter your email"
                            aria-describedby="email"
                            required
                          />
                        </div> */}

                        {/* FORM BTN */}
                        {/* <div className="form_btn_div_ftr">
                          <button
                            id="hero_section_email_btn"
                            className="form_btn_ftr fourth_text"
                            type="submit"
                          >
                            Yes, I'm in
                          </button>
                        </div> */}
                      </div>
                    ) : (
                      <span className="form_founder_span_ftr fourth_text">
                        Our founder - Jessica Dawda will reach out to you with
                        further details on your email ID shared.
                      </span>
                    )}
                  </div>
                </form>
              </div>
            </div>

            {/* Container - 2 */}
            <div className="container_ftr cont_2_ftr">
              <div className="cont2_ul_div_ftr">
                <ul className="cont2_ul_ftr second_text">
                  <li className="cont2_li_ftr">
                    <Link
                      id="main_nav_menu_link_team"
                      className="cont2_span_ftr"
                      to="/team"
                    >
                      Our Team
                    </Link>
                  </li>
                  <li className="cont2_li_ftr">
                    <Link
                      id="main_nav_menu_link_sci"
                      className="cont2_span_ftr"
                      to="/science"
                    >
                      Science
                    </Link>
                  </li>
                  <li className="cont2_li_ftr">
                    <Link
                      id="main_nav_menu_link_contact"
                      className="cont2_span_ftr"
                      to="/contact"
                    >
                      Contact us
                    </Link>
                  </li>
                  <li className="cont2_li_ftr">
                    <Link
                      id="main_nav_menu_link_blogs"
                      className="cont2_span_ftr"
                      to="/blogs"
                    >
                      Blogs
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            {/* Container - 3 */}
            <div className="container_ftr cont_3_ftr">
              <div className="cont3_main_div_ftr">
                {/* Cont-3 Heading */}
                <div className="cont3_heading_div_ftr">
                  <h3 className="cont3_heading_ftr second_text">
                    Let’s connect
                  </h3>
                </div>

                {/* Cont-3 Logos */}
                <div className="cont3_logo_div_ftr">
                  <ul className="cont3_ul_ftr">
                    {/* Facebook Logo */}
                    <li className="cont3_li_ftr">
                      <a
                        className="cont3_img_div_ftr"
                        href="https://www.facebook.com/profile.php?id=100092236381255"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="cont3_img_ftr"
                          src={fb_logo}
                          alt="Facebook Logo"
                        />
                      </a>
                    </li>

                    {/* Instagram Logo */}
                    <li className="cont3_li_ftr">
                      <a
                        className="cont3_img_div_ftr"
                        href="https://instagram.com/mindginie"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="cont3_img_ftr"
                          src={insta_logo}
                          alt="Instagram Logo"
                        />
                      </a>
                    </li>

                    {/* Linkedin Logo */}
                    <li className="cont3_li_ftr">
                      <a
                        className="cont3_img_div_ftr"
                        href="https://www.linkedin.com/company/mindginie/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="cont3_img_ftr"
                          src={lnkd_logo}
                          alt="Linkedin Logo"
                        />
                      </a>
                    </li>

                    {/* Youtube Logo */}
                    <li className="cont3_li_ftr">
                      <a
                        className="cont3_img_div_ftr"
                        href="https://youtube.com/@mindginie"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="cont3_img_ftr"
                          src={yt_logo}
                          alt="Youtube Logo"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Footer Trademark */}
          <div className="trd_mrk_ftr">
            <p className="fourth_text">
              Copyright 2023 Seiaum Tech Private Limited
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default Footer;
