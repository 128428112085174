import React from "react";

import "../../css/HomeStyles/Hero.css";
import "../../css/General.css";
import hero_img from "../../img/home/hero/hero_main_img.png";
// import img_1 from "../../img/home/hero/new/img-1.png";
// import img_2 from "../../img/home/hero/new/img-2.png";
// import img_3 from "../../img/home/hero/new/img-3.png";
// import google_play from "../../img/svg/google_play.svg";
import apple_btn from "../../img/svg/apple_download_btn.svg";

const Hero = () => {
  return (
    <>
      <section id="home" className="section_hero special_container">
        <div className="hero_written_data_main_div">
          {/* Hero Main text */}
          <div className="primary_heading_div_hero">
            <h1 className="primary_heading primary_heading_primary_color">
              Manage Stress,&nbsp;
            </h1>

            <h1 className="primary_heading flex flex-row">
              <span className="primary_heading_primary_color">Panic&nbsp;</span>

              <span className="primary_heading_primary_color">
                & Anxiety&nbsp;
              </span>
            </h1>

            <h1 className="primary_heading primary_heading_primary_color">
              in&nbsp;
              <span className="primary_heading_second_color">minutes.</span>
            </h1>

            {/* <div className="primary_heading">
              <h1 className="primary_heading_primary_color flex flex-row gap-2">
                Daily Life Stress.
              </h1>
            </div> */}
          </div>

          <div>
            <div className="primary_text_div_hero">
              <p className="primary_text lato_font_style">
                Track stress, panic & anxiety levels in real-time, receive
                instant alerts, understand your triggers & get personalized
                30-second relief techniques.
              </p>

              <p className="primary_text lato_font_style">
                {/* <span className="font-bold">Coming Soon</span> */}
                Enjoy a 7-day free trial!
              </p>
            </div>
          </div>

          <div className="hero_signUp_div">
            {/* DIV 1 */}
            {/* <div className="signUp_div_1_hero">
              <span className="poppins_font_style fourth_text"></span>
            </div> */}

            {/* DIV 2 */}

            {/* <a
              id="hero_google_play_btn"
              className="signUp_div_2_hero"
              href="https://play.google.com/store/search?q=mindginie&c=apps&hl=en-IN"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="signUp_google_play_btn"
                src={google_play}
                alt="Google Play Button"
              />
            </a> */}

            {/* DIV 3 */}
            <div className="signUp_txt_div signUp_div_3_hero">
              <span className="poppins_font_style fourth_text"></span>
            </div>

            {/* DIV 4 */}

            <a
              id="hero_app_store_btn"
              href="https://apps.apple.com/in/app/mindginie/id6462723261"
              className="signUp_div_4_hero"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={apple_btn}
                alt="App Store Button"
                className="signUp_iOS_store_btn"
              />
            </a>
          </div>
        </div>

        {/* Hero Image */}

        <div className="hero_main_img_div">
          <img
            src={hero_img}
            className="hero_main_img rounded-xl"
            alt="Hero Main"
          />
          {/* <img src={img_1} className="hero_main_img" alt="" />
          <img src={img_2} className="hero_main_img" alt="" />
          <img src={img_3} className="hero_main_img" alt="" /> */}
        </div>
      </section>
    </>
  );
};

export default Hero;
