import React from "react";
import "../../css/ResearchStyles/Research.css";
import "../../css/PrivacyStyles/Privacy.css";

const Research = () => {
  return (
    <>
      <section className="section_privacy poppins_font_style">
        <div className="privacy_policy_main_container">
          <div className="whyGinie_heading_div w-full flex justify-start items-center">
            <h1 className="privacy_heading_second">
              MindGinie App Stress Analysis using Smart Watch Data
            </h1>
          </div>

          <div className="privacy_policy_para_container w-full">
            <p>
              <span>
                &mdash; &nbsp; &nbsp; &nbsp; when Neurobiology meets Technology
              </span>
            </p>
          </div>

          {/* P1 */}
          <div className="privacy_policy_para_container">
            <p>
              <span>
                <span className="privacy_block">
                  Hans Selye defined stress as “a response to change in order to
                  maintain the state of stability or homology that the body has
                  maintained against the stimulus to break the mental and
                  physical balance and stability of the body
                  <sup className="superScript">[1]</sup>”. Stress was also
                  defined by Kenneth Hambly as a maladaptive state in which the
                  sympathetic nervous system is over activated, causing acute or
                  chronic physical, psychological, and behavioural impairment
                  <sup className="superScript">[2]</sup>.
                </span>
              </span>

              <span>
                There is a significant correlation between stress and heart
                rate. When a person experiences stress, their body’s natural
                stress response is activated, which leads to an increase in
                heart rate. This is due to the release of stress hormones such
                as cortisol and adrenaline, which prepare the body for a
                “fight-or-flight” response.
              </span>

              <span>
                Studies have shown that prolonged or chronic stress can have
                negative effects on cardiovascular health. It can contribute to
                the development of conditions such as hypertension, heart
                disease, and heart attacks and stroke or Cerebrovascular
                accidents (CVA). There is a significant connection between
                mental illness and stress including diseases like Depression,
                Anxiety Disorders, Acute Stress Reaction, Adjustment Disorder
                and PTSD (Post Traumatic Stress Disorder). This is where heart
                rate tracking can be a valuable tool for predicting and
                assessing stress levels- particularly when done over long time
                frames. When a person experiences stress, their heart rate
                typically increases as part of the body’s stress response. By
                monitoring changes in heart rate, individuals and healthcare
                professionals can gain insights into their stress levels and
                make informed decisions about stress management and self-care.
                They can take up stress reduction activities like mindfulness
                and other stress management techniques. However, there is a lack
                of gold standard measures for stress assessment.
              </span>

              <span>
                <span className="privacy_block">
                  HRV or Heart Rate Variability may help in indicating chronic
                  stress as a physiological stress indicator. As per
                  neuroimaging studies, HRV is associated with cortical regions
                  like ventromedial prefrontal cortex (VMPFC) which is involved
                  in stressful situation appraisal
                  <sup className="superScript">[3]</sup>. HRV is the fluctuation
                  of the length of heart beat intervals
                  <sup className="superScript">[4]</sup>. HRV represents the
                  ability of the heart to respond to a variety of physiological
                  and environmental stimuli
                  <sup className="superScript">[5]</sup>. Low HRV conveys a
                  monotonously regular heart rate. Moreover, low HRV is
                  associated with impaired regulatory and homeostatic autonomic
                  nervous system (ANS) functions, which reduce the body’s
                  ability to cope with internal and external stressors. Thus,
                  HRV is a non-invasive electrocardiographic method that can be
                  used to measure the ANS activity in a variety of clinical
                  situations (e.g., during psychological stress evaluation
                  <sup className="superScript">[6]</sup>. In 1967, Wolf viewed
                  HRV as reflecting “brain vagal-heart communication”
                  <sup className="superScript">[7]</sup>. His research showed
                  the connection between clinical research and psychophysiology,
                  thereby bringing the pieces of the brain-heart-stress puzzle
                  together. Since Stress includes biological and psychological
                  factors, objective and physiological evaluations as well as
                  self-reporting are all integral parts of assessment when
                  evaluating stress, thereby making HRV a useful parameter in
                  clinical practice<sup className="superScript">[8]</sup>.
                </span>
              </span>

              <span>
                <span className="privacy_block">
                  Continuous perception of threats is harmful to the human body
                  and affects the regulation of hippocampal circuits, endocrine
                  systems, ANS, and others
                  <sup className="superScript">[9-12]</sup>. If an organism is
                  to avoid a chronic state of threat, it is essential to
                  determine whether threat assessment is appropriate depending
                  on the context. The prefrontal cortex (PFC) and medial PFC
                  (mPFC), in particular, appear to be important in this
                  appraisal process as suggested in a meta-analysis study
                  <sup className="superScript">[8]</sup>.
                </span>
              </span>

              <span>
                <span className="privacy_block">
                  The use of biological algorithms to assess HR and HRV stress
                  responses is a novel use of currently available technology
                  <sup className="superScript">[13]</sup>. This has far reaching
                  benefits in providing many easy solutions to the medical field
                  with earlier diagnosis and better prognosis. Heart rate
                  tracking via smart watches is a simple yet effective way to
                  measure stress. Here are a few ways heart rate tracking can
                  help predict and assess stress:
                </span>
              </span>

              <span>
                Baseline Measurement: Establishing a baseline heart rate can
                help individuals understand their normal resting heart rate and
                identify deviations from it. When stressors occur, such as work
                deadlines or personal conflicts, individuals may notice an
                increase in their heart rate compared to their baseline,
                signalling heightened stress levels.
              </span>

              <span>
                Real-Time Monitoring: Continuous heart rate monitoring,
                especially during stressful situations or activities, can
                provide immediate feedback on stress levels. Wearable devices
                like smartwatches, fitness trackers, or mobile apps can track
                heart rate throughout the day, alerting individuals when their
                heart rate exceeds a certain threshold associated with stress.
              </span>

              <span>
                Heart Rate Variability (HRV): HRV is the measure of the
                variation in time intervals between consecutive heartbeats.
                Higher HRV indicates a more flexible and adaptive autonomic
                nervous system, which is associated with better stress
                resilience. By tracking HRV, individuals can gain insights into
                their physiological stress response and monitor improvements
                over time through stress management practices like mindfulness
                and relaxation techniques.
              </span>

              <span>
                The Role of Long-Term Trends: Analysing long-term heart rate
                data can help identify patterns and trends in stress levels. By
                tracking heart rate over weeks or months, individuals can
                identify triggers or events that consistently elevate their
                heart rate, indicating chronic or recurring stress. This
                information can guide interventions and lifestyle adjustments to
                tackle the underlying sources of stress.
              </span>

              <span>
                <span className="privacy_block">
                  It’s important to note that while heart rate tracking can
                  provide valuable insights into stress levels, it should be
                  combined with other subjective measures such as self-reporting
                  and behavioural observations for a comprehensive understanding
                  of stress. The connection between trauma or emotional stress
                  and heart rate has long been established. It was found by Pole
                  that individuals with PTSD often show increased cardiac
                  activity in the form of elevated resting heart rate and
                  enhanced heart rate reactivity to startling sounds and trauma
                  cues
                  <sup className="superScript">[14]</sup>.
                </span>
              </span>

              <span>
                <span className="privacy_block">
                  The Perceived Stress Scale and the Perceived Stress
                  Questionnaire are classical tools that help to assess stress
                  and gauge its severity. For example, heart rate rises during
                  exercise and the data might get misinterpreted as stress. Many
                  physical conditions and lifestyle habits can affect HRV
                  results, including physiological factors (e.g., breathing,
                  circadian rhythms, and posture), non-modifiable factors (e.g.,
                  age, sex, and genetic factors), modifiable lifestyle factors
                  (e.g., obesity, metabolic syndrome, physical activity,
                  smoking, and drinking), and other factors [e.g., medication
                  (e.g., anticholinergics, stimulants, and beta-blockers)]
                  <sup className="superScript">[15-21]</sup>. Moreover,
                  consulting with healthcare professionals like a Psychiatrist
                  or Psychologist may help in identifying the causes, intensity
                  and severity of stress. In stress management sessions, heart
                  rate data can guide the development of customised &
                  personalised strategies to manage stress effectively.
                </span>
              </span>

              <span>
                <span className="privacy_block">
                  Mindfulness practices have been found to be effective in
                  reducing stress levels, and consequently, heart rate.
                  Mindfulness involves actively paying attention to the present
                  moment, without judgement. It helps individuals become aware
                  of their thoughts, emotions, and bodily sensations, allowing
                  them to cultivate a non-reactive and accepting mind-set.
                  Various studies have shown that practising mindfulness
                  regularly can lead to a reduction in stress and improvements
                  in heart rate variability (HRV). There has been limited yet
                  significant research
                  <sup className="superScript">[22,23]</sup>) showing that
                  mindfulness exerts beneficial effects on the cardiovascular
                  system<sup className="superScript">[25,34]</sup>. The majority
                  of these studies have focused on acute changes from being in a
                  ‘mindful state’, while some studies cited above have
                  investigated changes in resting baseline HRV between long-term
                  mindfulness practitioners and novices (i.e. chronic changes).
                  Immediate physiological effects of mindfulness practice have
                  revealed increased activity in HRV in some studies
                  <sup className="superScript">[29]</sup>. Higher HRV is
                  correlated with better heart health.
                </span>
              </span>

              <span>
                Mindfulness promotes relaxation and activates the body's natural
                relaxation response, which can help counteract the effects of
                stress on heart rate. By being more present and aware,
                individuals can manage their reactions to stressors more
                effectively and decrease their physiological stress response.
              </span>

              <span>
                Overall, the correlation between stress and heart rate is
                well-established, and practising mindfulness can play a
                significant role in reducing stress levels and promoting a
                healthier heart and mind. In an era where technology strives to
                make life quick, easy and hassle-free, tracking your heart rate
                and monitoring its fluctuations may be the first step towards
                seeking prompt healthcare. Days are not far when apps will help
                you identify stress early, practise mindfulness regularly and
                even nudge you to visit the doctor, before it’s too late.
              </span>
            </p>
          </div>

          <div className="margin_top_small whyGinie_name_container w-full">
            <p>
              <span>Dr Pubali Chaudhuri</span>
              <span>Consultant Neuro-Psychiatrist</span>
              <span>
                MBBS (Honours) | MD Psychiatry (IOP, SSKM) | DCMH (Nimhans)
              </span>
            </p>
          </div>

          {/* P2 */}
          <div className="margin_top_big w-full">
            <div className="privacy_policy_para_container">
              <h2 className="privacy_heading_second">References:</h2>
            </div>

            <div className="privacy_policy_para_container">
              <ol>
                <li>
                  <span>
                    Selye H. The Stress of Life. New York: McGraw-Hill; 1956.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Campkin M. Stress management in primary care. Fam Pract.
                    2000; 17:98–99.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Thayer JF, Ahs F, Fredrikson M, Sollers JJ, Wager TD. A
                    meta-analysis of heart rate variability and neuroimaging
                    studies: implications for heart rate variability as a marker
                    of stress and health. Neurosci Biobehav Rev. 2012;
                    36:747–756.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Malik M, Camm AJ. Heart Rate Variability. Armonk, NY: Futura
                    Pub. Co; 1995.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Rajendra Acharya U, Paul Joseph K, Kannathal N, Lim CM, Suri
                    JS. Heart rate variability: a review. Med Biol Eng Comput.
                    2006; 44:1031–1051.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Sztajzel J. Heart rate variability: a noninvasive
                    electrocardiographic method to measure the autonomic nervous
                    system. Swiss Med Wkly. 2004; 134:514–522.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Wolf S. The end of the rope: the role of the brain in
                    cardiac death. Can Med Assoc J. 1967; 97:1022– 1025.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Stress and Heart Rate Variability: A Meta-Analysis and
                    Review of the Literature, Hye-Geum Kim,1 EunJin Cheon,2
                    Young Hwan Lee,2 ;2018
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Seeman TE, McEwen BS, Rowe JW, Singer BH. Allostatic load as
                    a marker of cumulative biological risk: MacArthur studies of
                    successful aging. Proc Natl Acad Sci U S A.
                    2001;98:4770–4775. [PMC free article]
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Sapolsky RM. Why stress is bad for your brain. Science.
                    1996;273:749–750.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    McEwen BS. From molecules to mind. Stress, individual
                    differences, and the social environment. Ann N Y Acad Sci.
                    2001;935:42–49.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Chrousos GP, Kino T. Interactive functional specificity of
                    the stress and immune responses: the ying, the yang, and the
                    defense against 2 major classes of bacteria. J Infect Dis.
                    2005;192:551–555.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Stress Watch: The Use of Heart Rate and Heart Rate
                    Variability to Detect Stress: A Pilot Study Using Smart
                    Watch Wearables; 2022 Jan; 22(1): 151.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Pole N. The psychophysiology of posttraumatic stress
                    disorder: A meta-analysis. Psychological Bulletin. 2007;
                    133:725–746.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Romanowicz M, Schmidt JE, Bostwick JM, Mrazek DA, Karpyak
                    VM. Changes in heart rate variability associated with acute
                    alcohol consumption: current knowledge and implications for
                    practice and research. Alcohol Clin Exp Res. 2011;
                    35:1092–1105.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Spaak J, Tomlinson G, McGowan CL, Soleas GJ, Morris BL,
                    Picton P, et al. Dose-related effects of red wine and
                    alcohol on heart rate variability. Am J Physiol Heart Circ
                    Physiol. 2010;298:H2226–H2231. [PubMed] [Google Scholar]
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Dinas PC, Koutedakis Y, Flouris AD. Effects of active and
                    passive tobacco cigarette smoking on heart rate variability.
                    Int J Cardiol. 2013;163:109–115. [PubMed] [Google Scholar]
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Felber Dietrich D, Ackermann-Liebrich U, Schindler C,
                    Barthelemy JC, Brandli O, Gold DR, et al. Effect of physical
                    activity on heart rate variability in normal weight,
                    overweight and obese subjects: results from the SAPALDIA
                    study. Eur J Appl Physiol. 2008;104:557–565. [PMC free
                    article] [PubMed] [Google Scholar]
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Min KB, Min JY, Paek D, Cho SI. The impact of the components
                    of metabolic syndrome on heart rate variability: using the
                    NCEP-ATP III and IDF definitions. Pacing Clin
                    Electrophysiol. 2008;31:584–591. [PubMed] [Google Scholar]
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Altuncu ME, Baspinar O, Keskin M. The use of short-term
                    analysis of heart rate variability to assess autonomic
                    function in obese children and its relationship with
                    metabolic syndrome.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Taryn Chalmers,1 Blake Anthony Hickey,1 Phillip Newton,2
                    Chin-Teng Lin,3 David Sibbritt,4 Craig S. McLachlan,5
                    Roderick Clifton-Bligh,6 John Morley,7 and Sara Lal,1
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Olex S, Newberg A, Figueredo VM. Meditation: should a
                    cardiologist care? Int J Cardiol. 2013;168(3):1805–10.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Christodoulou G, Salami N, Black DS. The Utility of Heart
                    Rate Variability in Mindfulness Research. Mindfulness.
                    2020;11(3):554–70.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Adler-Neal AL, Waugh CE, Garland EL, Shaltout HA, Diz DI,
                    Zeidan F. The Role of Heart Rate Variability in
                    Mindfulness-Based Pain Relief. J Pain. 2019.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Azam MA, Katz J, Mohabir V, Ritvo P. Individuals with
                    tension and migraine headaches exhibit increased heart rate
                    variability during post-stress mindfulness meditation
                    practice but a decrease during a post-stress control
                    condition–A randomized, controlled experiment. Int J
                    Psychophysiol. 2016; 110:66– 74.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Crosswell AD, Moreno PI, Raposa EB, Motivala SJ, Stanton AL,
                    Ganz PA, et al. Effects of mindfulness training on emotional
                    and physiologic recovery from induced negative affect.
                    Psychoneuroendocrinology. 2017; 86:78–86.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Delgado-Pastor LC, Perakakis P, Subramanya P, Telles S, Vila
                    J. Mindfulness (vipassana) meditation: Effects on P3b
                    event-related potential and heart rate variability.
                    International Journal of Psychophysiology.
                    2013;90(2):207–14.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Ditto B, Eclache M, Goldman N. Short-term autonomic and
                    cardiovascular effects of mindfulness body scan meditation.
                    Ann Behav Med. 2006;32(3):227–34.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Garland EL, Froeliger B, Howard MO. Effects of
                    mindfulness-oriented recovery enhancement on reward
                    responsiveness and opioid cue-reactivity.
                    Psychopharmacology. 2014;231(16):3229–38.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Krygier JR, Heathers JA, Shahrestani S, Abbott M, Gross JJ,
                    Kemp AH. Mindfulness meditation, wellbeing, and heart rate
                    variability: A preliminary investigation into the impact of
                    intensive Vipassana meditation. Int J Psychophysiol.
                    2013;89(3):305–13.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Lumma AL, Kok BE, Singer T. Is meditation always relaxing?
                    Investigating heart rate, heart rate variability,
                    experienced effort and likeability during training of three
                    types of meditation. Int J Psychophysiol. 2015;97(1):38–45.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Murata T, Takahashi T, Hamada T, Omori M, Kosaka H, Yoshida
                    H, et al. Individual trait anxiety levels characterizing the
                    properties of Zen meditation. Neuropsychobiology. 2004
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Peressutti C, Martin-Gonzalez JM, Garcia-Manso JM. Does
                    mindfulness meditation shift the cardiac autonomic nervous
                    system to a highly orderly operational state? International
                    Journal of Cardiology.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>
                    Peressutti C, Martín-González JMM, García-Manso J, Mesa D.
                    Heart rate dynamics in different levels of zen meditation.
                    International Journal of Cardiology. 2010;145(1):142–6.
                  </span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>Perceived Stress Scale</span>
                </li>
              </ol>

              <ol>
                <li>
                  <span>Perceived Stress Questionnaire</span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Research;
