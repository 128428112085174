import React from "react";

import "../../css/HomeStyles/LearnMore.css";
import underline_img from "../../img/home/learn_more/underline_img.png";
import watch_learn from "../../img/home/learn_more/watch_learn.png";
import notify_learn from "../../img/home/learn_more/notify_learn.png";
import spa_learn from "../../img/home/learn_more/spa_learn.png";

const LearnMore = () => {
  return (
    <>
      <section className="section_learn-more">
        <div className="container">
          <div className="main-container_lm">
            {/* Heading */}
            <div className="prim-heading-div_lm">
              <h2 className="second_heading">
                Stress, Panic & Anxiety Care at your fingertips
              </h2>
            </div>

            {/* Text */}
            <div className="second_text_div-lm">
              <p className="primary_text_24px lato_font_style font-medium">
                Realising that you’re stressed is the first step in managing it.
              </p>
              <p className="primary_text_24px lato_font_style font-medium">
                Stress Monitor is a wearable-based{" "}
                <span className="txt_lm_second_color">AI-powered</span> tool
                that helps you identify and manage stressful situations in{" "}
                <span className="txt_lm_second_color">3 simple steps.</span>
              </p>
              <img
                className="underline_img_lm"
                src={underline_img}
                alt="Text Underline"
              />
            </div>

            {/* Tiles */}
            <div className="main_container_tiles_lm">
              <div className="container_tiles_lm">
                {/* TILE 1 */}
                <div className="tile_box_lm">
                  <div className="tile_img_div_lm">
                    <img
                      className="tile_img_lm"
                      src={watch_learn}
                      alt="Watch Icon"
                    />
                  </div>

                  <div className="tile_heading_box_lm">
                    <div className="tile_heading_index_div_lm">
                      <span className="tile_heading_index_txt_lm">1</span>
                    </div>

                    <div className="tile_heading_div_lm">
                      <h3 className="fourth_heading tile_heading_lm">
                        Connect Your Wearable
                      </h3>
                    </div>

                    <div className="tile_text_box_lm">
                      <p className="primary_text tile_text_lm lato_font_style">
                        Sync your Apple Watch. We guarantee full data privacy.
                      </p>
                    </div>
                  </div>
                </div>

                {/* TILE 2 */}
                <div className="tile_box_lm">
                  <div className="tile_img_div_lm">
                    <img
                      className="tile_img_lm"
                      src={notify_learn}
                      alt="Notification Icon"
                    />
                  </div>

                  <div className="tile_heading_box_lm">
                    <div className="tile_heading_index_div_lm">
                      <span className="tile_heading_index_txt_lm">2</span>
                    </div>

                    <div className="tile_heading_div_lm">
                      <h3 className="fourth_heading tile_heading_lm">
                        Get Real-time Alerts
                      </h3>
                    </div>

                    <div className="tile_text_box_lm">
                      <p className="primary_text tile_text_lm lato_font_style">
                        Identifying stress is the first step in overcoming it.
                        Receive real-time alerts when you are stressed.
                      </p>
                    </div>
                  </div>
                </div>

                {/* TILE 3 */}
                <div className="tile_box_lm">
                  <div className="tile_img_div_lm">
                    <img
                      className="tile_img_lm"
                      src={spa_learn}
                      alt="Similing Emoji Icon"
                    />
                  </div>

                  <div className="tile_heading_box_lm">
                    <div className="tile_heading_index_div_lm">
                      <span className="tile_heading_index_txt_lm">3</span>
                    </div>

                    <div className="tile_heading_div_lm">
                      <h3 className="fourth_heading tile_heading_lm">
                        De-stress & Relax
                      </h3>
                    </div>

                    <div className="tile_text_box_lm">
                      <p className="primary_text tile_text_lm lato_font_style">
                        Get 30-second personalised tips & techniques tailored to
                        your stress triggers
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LearnMore;
