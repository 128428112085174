import React from "react";
import "../../css/PrivacyStyles/Privacy.css";

const PrivacyPolicy = () => {
  return (
    <>
      <section className="section_privacy poppins_font_style">
        <div className="privacy_policy_main_container">
          <h1 className="privacy_heading_primary">Privacy Policy</h1>

          {/* P1 */}
          <div className="privacy_policy_para_container">
            <p>
              <span>
                <span className="privacy_block">
                  This Privacy Policy governs how we, Seiaum Tech Private Ltd.
                  and our affiliates, successors and assigns (“
                  <span className="privacy_block font-semibold">MindGinie</span>
                  ”, “
                  <span className="privacy_block font-semibold">Company</span>
                  ”, “<span className="privacy_block font-semibold">we</span>
                  ”, “<span className="privacy_block font-semibold">us</span>”
                  or “<span className="privacy_block font-semibold">our</span>
                  ”) collect, use, share and process users Personal Data,
                  Sensitive Personal Data, and Non-Personal Data (all defined
                  below), that users voluntarily provide us or we automatically
                  collect through your use of the MindGinie app (“
                  <span className="privacy_block font-semibold">App</span>”) and
                  website{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="privacy_url_link font-semibold"
                    href="https://www.mindginie.com"
                  >
                    https://www.mindginie.com
                  </a>{" "}
                  (“<span className="privacy_block font-semibold">Website</span>
                  ”) in the course of availing services that are made available
                  on the said App and Website (“
                  <span className="privacy_block font-semibold">Services</span>
                  ”, as defined in the Terms of Services) to users and other
                  information that is collected from the visitors. App and
                  Website shall collectively be referred to as the Platform.
                </span>
              </span>

              <span>
                Please read this Privacy Policy carefully. By accessing the
                Platform or using the Services, you agree to be bound by the
                terms described herein and all the terms incorporated by
                reference.
              </span>
            </p>
          </div>

          {/* P2 */}
          <div>
            <div className="privacy_policy_para_container">
              <h2 className="privacy_heading_second uppercase">
                What is Personal Data?
              </h2>
              <p>
                <span>
                  <span className="privacy_block">
                    “
                    <span className="privacy_block font-semibold">
                      Personal Data
                    </span>
                    ” refers to any information that can be used to directly or
                    indirectly identify you. It includes de- identified data
                    that, when linked to other information available to us,
                    would enable us to identify you. Personal data does not
                    include data that has been irreversibly anonymised or
                    aggregated so that we cannot identify you through it, even
                    in conjunction with other information.{" "}
                    <span className="privacy_block font-semibold">
                      Sensitive Personal Data or Information (“SPDI”)
                    </span>
                    includes information relating to the following:
                  </span>
                </span>
              </p>
            </div>

            <div className="privacy_policy_para_container">
              <ol>
                <li>
                  <span>Passwords</span>
                </li>
                <li>
                  <span>
                    Financial information such as bank account or credit card or
                    debit card or other payment instrument details
                  </span>
                </li>
                <li>
                  <span>Heart Rate & Physical Activity</span>
                </li>
                <li>
                  <span>Sexual orientation</span>
                </li>
                <li>
                  <span>Medical Records if any</span>
                </li>
                <li>
                  <span>
                    Any detail relating to the above sub-clauses or any other
                    sensitive personal data or information you provide to us for
                    providing the Services.
                  </span>
                </li>
                <li>
                  <span>
                    Any of the information received under the above sub-clauses
                    by us under lawful contract or otherwise. However, any data
                    / information relating to an individual that is freely
                    available or accessible in public domain or furnished under
                    any law shall not qualify as SPDI.
                  </span>
                </li>
              </ol>
            </div>
          </div>

          {/* P3 */}
          <div>
            <div className="privacy_policy_para_container">
              <h2 className="privacy_heading_second uppercase">
                What is Non-Personal Data?
              </h2>
              <p>
                <span>
                  <span className="privacy_block">
                    “
                    <span className="privacy_block font-semibold">
                      Non-Personal Data
                    </span>
                    ” refers to any non-personal information collected from
                    users pursuant to their use of the Services, including but
                    not limited to the browser name, the type of computer and
                    technical information about users, means of connection to
                    the Services such as the operating system, the internet
                    service providers utilised and other similar information.
                    This information does not identify you personally and cannot
                    be linked back to you unless you identify yourself.
                  </span>
                </span>
                <span>
                  <span className="privacy_block">
                    Personal Data, Non-Personal Data, and SPDI or any other
                    information shall collectively be referred to as the ‘
                    <span className="privacy_block font-semibold">
                      Information
                    </span>
                    ’.
                    <span className="privacy_block font-semibold">
                      “Third-Party”
                    </span>{" "}
                    refers to any person or entity other than you or us. By
                    signing up on the Platform, and / or using our Services you
                    represent that you voluntarily provide us with Personal
                    Data, including Sensitive Personal Data and consent to their
                    collection, use and disclosure in accordance with this
                    Privacy Policy. You also represent that you are duly
                    authorised by any Third-Party (including a minor or
                    employee) whose information you share with us. We shall act
                    as per your representation of authority and shall not make
                    any independent enquiries to ascertain the veracity of your
                    authorisation. In the event you do not have sufficient
                    authorisation you shall be solely responsible for your acts
                    and omissions including sharing of information with us by
                    you and the consequential processing and actions taken by us
                    in accordance with this Privacy Policy.
                  </span>
                </span>
              </p>
            </div>
          </div>

          {/* P4 */}
          <div>
            <div className="privacy_policy_para_container">
              <h2 className="privacy_heading_second uppercase">
                INFORMATION WE COLLECT
              </h2>
              <p>
                <span>
                  When you sign up or register on the Platform to use our
                  Services, and during the course of actual usage of our
                  Services, the types of Information that are collected by us
                  include the following:
                </span>
              </p>
            </div>

            <div className="privacy_policy_para_container">
              <ol>
                <li>
                  <span>
                    For registering user accounts (“User Account”) – Personal
                    Data such as First and Last Name, Date of Birth, Email, and
                    Non-Personal Data such as Gender, Country, City, Mobile OS
                    (iOS/Android), Third-Party wearable make and model, if
                    applicable.
                  </span>
                </li>
                <li>
                  <span>
                    You may also register for a User Account using your existing
                    Apple or Google account, or social media accounts (such as
                    Facebook and Instagram, as applicable), and login
                    credentials (your “Third-Party Site Accounts”). As part of
                    the functionality of the Services, you may link your User
                    Account with Third Party Site Accounts, by either: (i)
                    providing your Third -Party Site Account login information
                    to us through the Services; or (ii) allowing us to access
                    your Third -Party Site Account, as is permitted under the
                    applicable terms and conditions that govern your use of each
                    Third -Party Site Account. In the event you are registering
                    for a User Account using your Third -Party Site Account, you
                    represent that you are entitled to disclose your Third-Party
                    Site Account login information to us and/or grant us access
                    to your Third-Party Site Account (including, but not limited
                    to, for the purposes described herein), without breach by
                    you of any of the terms and conditions that govern your use
                    of the applicable Third-Party Site Account and without
                    obligating us to pay any fees or making us subject to any
                    usage limitations imposed by the applicable Third-Party Site
                    Account service provider.
                  </span>
                </li>
                <li>
                  <span>For personalised recommendations –</span>
                  <ol>
                    <li>
                      <span>
                        Data we collect with your explicit permission: Subject
                        to your permission,
                      </span>

                      <ol>
                        <li>
                          <span>
                            We import Non-Personal Data such as your resting &
                            dynamic heart rate from your wearable devices via
                            health connect or apple health. The use of
                            Information received from Health Connect Permissions
                            policy, including the Limited Use requirements.
                          </span>
                        </li>
                        <li>
                          <span>
                            The dynamic & resting heart rate is collected to
                            measure your stress levels & give you stress related
                            recommendations.
                          </span>
                        </li>
                        <li>
                          <span>
                            The steps, calories , dynamic & resting heart rate
                            is collected to measure your stress levels & give
                            you stress related recommendations.
                          </span>
                        </li>
                      </ol>
                    </li>
                    <li>
                      <span>
                        To clarify, we will have access only to the data types
                        that you choose to opt-in for us to access and store in
                        our servers. The data read from such integration will be
                        used solely for measuring and improving the efficacy of
                        your experience of our Services. The data synced from
                        Apple Health or Google Fit or any Third-Party platform
                        that integrates with Apple Health or Google Fit is not
                        collected, used or disclosed to any Third-Party for the
                        purpose of marketing and advertising.
                      </span>
                    </li>
                    <li>
                      <span>
                        Automatic Collection of Information : If you access the
                        features of the Platform, we may automatically gather
                        and store certain Non-Personal Data about your visit
                        such as the type of browser and operating system used to
                        access our Website, the date and time you accessed our
                        Website, and if you were linked to our Website from
                        another website, the address of that website, as well as
                        additional information related to your visit may be
                        collected.
                      </span>
                    </li>
                    <li>
                      <span>
                        Voluntary Collection of Information : We collect
                        Personal Data and SPDI only voluntarily and knowingly
                        provided by our users. In order to assess and customise
                        the user experience, we may collect other information
                        from our users as our Services are further developed.
                        You can choose not to provide us with certain
                        information, in which case you may not be able to
                        register with us or to take advantage of some of our
                        features / Services
                      </span>
                    </li>
                    <li>
                      <span>
                        Billing & Payment Related Information : Users may also
                        be required to make a digital or offline payment to
                        subscribe to our Services, as per our policies. In order
                        to collect the subscription fee from our users to access
                        and use the premium Services, our authorised Third-Party
                        payment processors assist us in processing your payment
                        information securely.
                      </span>
                    </li>
                    <li>
                      <span>
                        Aggregated Personal Data: In an ongoing effort to better
                        understand and serve the users of Services, we may
                        conduct research on our customer demographics and
                        behaviour based on the Personal Data and other
                        information provided to us. This research may be
                        compiled and analysed on an aggregate basis, and we may
                        share this aggregate data with our affiliates, agents
                        and business partners. This aggregate information does
                        not identify you personally. We may also disclose
                        aggregated user statistics in order to describe our
                        Services to current and prospective business partners,
                        and to other third parties for other lawful purposes.
                      </span>
                    </li>
                    <li>
                      <span>
                        We undertake to protect the Personal Data and SPDI
                        provided by you in accordance with reasonable security
                        practices and procedures and undertake not to transfer
                        or disclose your Personal Data and SPDI to a Third Party
                        for any purpose not authorised in this Privacy Policy
                        without your prior consent. However, Third-Party
                        processors' use of your information is governed by their
                        respective privacy policies, which may or may not
                        contain privacy protections as protective as this
                        Privacy Policy. We suggest that you review their
                        respective privacy policies.
                      </span>
                    </li>
                    <li>
                      <span>
                        The type of Information collected from you may vary
                        based on your interaction with the Platform and
                        preferred use of the Services.
                      </span>
                    </li>
                    <li>
                      <span>
                        We also collect any Information that you voluntarily
                        choose to provide to us through Platform, email, during
                        interaction with us on call or chat and other modes of
                        communication.
                      </span>
                    </li>
                    <li>
                      <span>
                        We may also collect Information you have provided to any
                        company or its affiliates, associates, subsidiary,
                        holding company to whom you have given consent for
                        sharing of such Information.
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>

          {/* P5 */}
          <div>
            <div className="privacy_policy_para_container">
              <h2 className="privacy_heading_second uppercase">
                PURPOSE OF COLLECTION:
              </h2>
              <p>
                <span>
                  We use your Information for purposes that include the
                  following:
                </span>
              </p>
            </div>

            <div className="privacy_policy_para_container">
              <ol>
                <li>
                  <span>
                    The steps, calories , dynamic & resting heart rate is
                    collected to measure your stress levels & give you stress
                    related recommendations.
                  </span>
                </li>
                <li>
                  <span>
                    Your Registration Information for the purpose of receiving
                    our Services, identification, communication, notification
                    and for fulfilment of the Terms of Services.
                  </span>
                </li>
                <li>
                  <span>
                    Addressing your requests, queries and complaints, if any,
                    pertaining to our Services; taking feedback, assisting you
                    with completion of transactions or other issues relating to
                    the use of Services and other customer care related
                    activities.
                  </span>
                </li>
                <li>
                  <span>Diagnose technical glitches.</span>
                </li>
                <li>
                  <span>
                    Creating insights for corporate / business strategy and
                    marketing operations of MindGinie.
                  </span>
                </li>
                <li>
                  <span>
                    Developing machine learning algorithms and tools to improve
                    targeting of Services, and other products and services.
                  </span>
                </li>
                <li>
                  <span>
                    Contacting you to provide information on existing or new
                    Services, features, products, special promotions or offers,
                    both of MindGinie and affiliates as well as third-party
                    offers or products with whom we have a tie-up and which are
                    relevant to use of the Services.
                  </span>
                </li>
                <li>
                  <span>
                    Technical administration and customization of Platform, and
                    other general administrative and business purposes.
                  </span>
                </li>
                <li>
                  <span>
                    Research, analysis, case studies for the development and
                    improvement of products and Services.
                  </span>
                </li>
                <li>
                  <span>
                    Disclosure as required to government authorities in
                    compliance with applicable law.
                  </span>
                </li>
                <li>
                  <span>
                    Investigating, enforcing and resolving any disputes or
                    grievances.
                  </span>
                </li>
                <li>
                  <span>
                    Create your unified profile with analytics and insights
                    generated through processing your Personal Data.
                  </span>
                </li>
                <li>
                  <span>
                    Processing any orders/requests and fulfil any orders you may
                    place using our Services.
                  </span>
                </li>
                <li>
                  <span>
                    For providing recommendations to customise or tailor your
                    experience of the Services.
                  </span>
                </li>
                <li>
                  <span>
                    Offering you personalised Services and targeted
                    advertisements of our healthcare and wellness plans and
                    offering you customised health insights.
                  </span>
                </li>
                <li>
                  <span>
                    For providing any other services to users with their
                    consent.
                  </span>
                </li>
                <li>
                  <span>To present, operate or improve the Services.</span>
                </li>
                <li>
                  <span>
                    To push transactional, marketing, and other product and
                    service related notifications.
                  </span>
                </li>
                <li>
                  <span>
                    For performing data analysis and testing, processing of
                    statistics for advertising, affiliate marketing, analytics.
                  </span>
                </li>
                <li>
                  <span>
                    We may periodically send emails (including marketing emails)
                    about upgrades to our existing products and services, or
                    other similar products and services, special offers, or
                    other information which we reasonably believe you may find
                    relevant and interesting using the email address which you
                    have provided. You may choose to opt-out of receiving
                    promotional emails via the “Unsubscribe” link in every such
                    promotional email communication. Once you unsubscribe, we
                    will no longer send you promotional/marketing emails however
                    you will continue to receive servicing and transactional
                    emails.
                  </span>
                </li>
                <li>
                  <span>To investigate fraud or abuse.</span>
                </li>
                <li>
                  <span>
                    To use statistical information in any way permitted under
                    law, including from Third-Parties in connection with
                    commercial and marketing efforts.
                  </span>
                </li>
                <li>
                  <span>
                    To comply with the law and respond to legal requests.
                  </span>
                </li>
                <li>
                  <span>
                    In any other way that we specify at the time of seeking
                    information from the users.
                  </span>
                </li>
              </ol>
            </div>
          </div>

          {/* P6 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              PRINCIPLES OF PROCESSING:
            </h2>
            <p>
              <span>
                We will not process Information in a way that is incompatible
                with the purposes for which it has been collected or
                subsequently authorised by you or collect any Information that
                is not needed for the mentioned purposes. For any new purpose,
                we will ask your separate explicit consent. We also undertake to
                collect only such Information that is strictly needed for the
                mentioned purposes.
              </span>
            </p>
          </div>

          {/* P7.1 */}

          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              LIMITED USE DISCLOSURES:
            </h2>

            <ol>
              <li>
                <span>
                  The use of information received from Health Connect will
                  adhere to the Health Connect Permissions policy, including the
                  Limited Use requirements.
                </span>

                <span>
                  The use of information received from Apple Health will adhere
                  to the Apple Health Permissions policy, including the Limited
                  Use requirements.
                </span>
              </li>
            </ol>
          </div>

          {/* P7.2 */}

          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">DISCLOSURES:</h2>
            <p>
              <span>
                MindGinie is not in the business of selling your Information. We
                consider this Information to be a vital part of our relationship
                with you. There are, however, certain circumstances in which we
                may share your Information with certain third parties without
                further notice to you, as set forth below:
              </span>

              <span>
                Disclosure for Legal Purposes: We may share information with law
                enforcement agencies pursuant to lawful requests for
                Information, and otherwise as required under any law applicable
                at the given time, both in India and outside India. We may also
                disclose Information if we determine in good faith that
                disclosure is reasonably necessary to protect our rights and
                pursue available legal remedies, enforce our terms and
                conditions, investigate fraud, or protect our operations or
                users.
              </span>

              <span>
                Service Providers: We share Information with companies that
                provide Services on our behalf, such as website hosting, data
                storage, software services, email services, marketing,
                fulfilling customer orders, providing payment-related services
                including payment aggregation, data analytics, data mining,
                providing customer services, and conducting surveys, as
                permitted by applicable law. These companies may be located
                within or outside India, but in any case, they are obligated to
                protect your data.
              </span>

              <span>
                Group Entities/Affiliates/Business Partners/Employees: We may
                also share Information with employees, data processors,
                consultants, business partners, and technology partners on a
                need-to-know basis. Such entities would be contractually
                obligated to maintain confidentiality in relation to your
                Information.
              </span>

              <span>
                Business Affiliates: We may disclose or transfer some of your
                Information to Company group entities, affiliates, associates,
                subsidiary, holding company of the Company, associates and
                subsidiary of holding company of the Company including foreign
                entities, and in particular group companies and affiliates who
                are involved in the provision of Services, to the extent
                permitted by applicable law.
              </span>

              <span>
                Business Reorganisation: In the event of a merger,
                reorganisation, acquisition, joint venture, assignment,
                spin-off, transfer, asset sale, or sale or disposition of all or
                any portion of our business, including in connection with any
                bankruptcy or similar proceedings, we may transfer any and all
                Information to the relevant Third-Party with the same rights of
                access and use.
              </span>

              <span>
                Other Third-Parties: We may disclose Information to any
                Third-Party if necessary to provide or improve our Services,
                fulfil any lawful contractual obligation we are bound by, and
                any other activity related to the purposes identified in this
                Privacy Policy and the terms and conditions you agree to when
                you use our Services. Anonymized, aggregated data may be shared
                with advertisers, research firms, and other partners.
              </span>

              <span>
                Exclusions: This Privacy Policy does not apply to any
                Information collected by MindGinie other than Information
                collected through the Services. This Privacy Policy shall not
                apply to any unsolicited information you provide to us through
                this Platform or through any other means. This includes, but is
                not limited to, information posted to any public areas of the
                Platform (collectively, “Public Areas”), any ideas for new
                products or modifications to existing products, and other
                unsolicited submissions (collectively, “Unsolicited
                Information”). All Unsolicited Information shall be deemed to be
                non-confidential and MindGinie shall be free to reproduce, use,
                disclose, and distribute such Unsolicited Information to others
                without limitation or attribution.
              </span>
            </p>
          </div>

          {/* P8 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              HOW WE PROTECT INFORMATION:
            </h2>

            <p>
              <span>
                We are committed towards maintaining the privacy of the
                Information uploaded by you on the Platform and complying with
                the industry standard security safeguards for the purpose of
                securing the Platform and the information provided / uploaded by
                you. However, we cannot guarantee absolute security as no method
                of protection and transmission of Information is completely
                secure. Therefore, while we strive to protect your Information,
                you agree and acknowledge that (i) there are security and
                privacy limitations of the internet which are beyond our
                control; (ii) the security, integrity, and privacy of any and
                all Information and data exchanged between you and our Platform
                cannot be guaranteed; and (iii) any such Information and data
                may be viewed or tampered with in transit by a Third-Party,
                despite our best efforts.
              </span>

              <span>
                We use reasonable technical, administrative, and physical
                security measures for the purpose of safeguarding all data you
                share with us. We also have comprehensive internal policies in
                place to prevent unauthorised access to your data. We take
                adequate steps to ensure that third parties we share data with
                also adopt reasonable levels of security practices and
                procedures to ensure the privacy and security of your
                information.
              </span>

              <span>
                However, we are not responsible for any loss, unauthorised
                access, safety issue or any harm caused to you by any misuse of
                your Information, unless it is a direct and foreseeable
                consequence of negligence and non- compliance on our part only.
                You hereby acknowledge that we are not responsible, in
                particular, for any Third Party action or action on your part
                leading to loss, damage or harm to you or any other person.
              </span>

              <span>
                For any data loss or theft due to unauthorised access to your
                electronic devices through which you avail our Services, we
                shall not be held liable for any loss whatsoever incurred by
                you. Further, you are liable to indemnify us as per the Terms of
                Services.
              </span>
            </p>
          </div>

          {/* P9 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              ACCESS TO THIRD-PARTY:
            </h2>

            <p>
              <span>
                We do not sell or share your Information to any Third-Party,
                including but not limited to marketing companies, agencies,
                advertisers, or any similar companies in exchange for
                fees/payments. We also do not share or allow any Third-Party to
                use your Information stored with us for any other purpose except
                consented by you or if required under any legal obligations or
                this Privacy Policy.
              </span>

              <span>
                However, we may use certain standard advertising tools on our
                marketing sites which, provided you have allowed it in your
                cookie preferences, sends Information to the tool providers,
                such as Google. You can use the privacy setting and cookie
                preferences to opt-out of the use of these advertising tools. We
                may also offer electronic newsletters to which you may
                voluntarily subscribe at any time. You may choose to stop
                receiving our newsletter or marketing emails by following the
                unsubscribe instructions included in these emails or by
                contacting us. However, you will continue to receive essential
                service and transactional emails.
              </span>

              <span>
                We may engage Third-Party processors to perform particular
                operations with your Information for us. Such Third-Party
                processors are companies that help us operate the Services,
                support our communication with you or perform other
                Services-related activities. They may process certain
                Information on our behalf to accomplish the goals related to the
                Service functions and associated activities. Third-Party
                processors act only in accordance with our instructions and
                process only such amount of Information as we instruct them to
                process. We remain fully liable for any acts or omissions of our
                Third-Party processors and undertake to execute formal data
                processing agreements with them to the extent required by
                applicable law.
              </span>
            </p>
          </div>

          {/* P10 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              DATA RETENTION:
            </h2>

            <p>
              <span>
                We store your Information in accordance with applicable laws,
                which means we keep your data for as long as necessary to
                provide you with our Services or as may be required under any
                law. We shall store your Information for lawful purposes only.
                We keep de-identified data for research and statistical purposes
                for a longer period.
              </span>

              <span>
                If you close your User Account, we have no obligation to retain
                your data, and we may delete any or all of your data without
                liability. However, we may retain data related to you if we
                believe it may be necessary to prevent fraud or future abuse, or
                if required by law, or for other legitimate purposes. We may
                continue to store your data in anonymised or de-identified form
                for analytical, research or other purposes for which your
                information is collected as previously indicated.
              </span>

              <span>
                If you have elected to receive marketing communications from us,
                we will retain Information about your marketing preferences for
                a reasonable period of time, which will be calculated based on
                the date you last expressed interest in our content, products,
                or Services.
              </span>
            </p>
          </div>

          {/* P11 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">YOUR RIGHTS:</h2>

            <p>
              <span>
                We follow our users’ instructions about Information we hold for
                that user. You may access your Information collected by us,
                request reasons for collection, correct your Information,
                request deletion of your Information, request restricted
                processing of your Information, the category of entities with
                whom we have shared it and the reason for any disclosure,
                request a portable copy of the Information you have provided,
                object to processing of your Information, such as for direct
                marketing, and where we obtain your consent, you may withdraw
                such consent at any time.
              </span>

              <span>
                You are free to not share any medical or other information that
                you consider confidential and withdraw consent for us to use
                data that you have already provided. In the event that you
                refuse to share any information or withdraw consent to process
                information that you have previously given to us, we reserve the
                right to restrict or deny the provision of our Services for
                which we consider such information to be necessary.
              </span>

              <span>
                You also have the right to file a complaint with the supervisory
                authority India. You can file a complaint with the Information
                Commissioner’s Office.
              </span>
            </p>
          </div>

          {/* P12 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              DATA STORAGE AND TRANSFER:
            </h2>

            <p>
              <span>
                You agree that Information collected may be stored and processed
                in any country where we rent servers, or where we or our
                affiliates, subsidiaries, or agents maintain facilities in order
                to provide Services, and by accessing, registering for, or using
                the Services, you consent to any such transfer of Information.
                We generally store Information closest to the users where they
                are located through our authorised Third-Party data centres. We
                may transfer your Information to Third-Parties acting on our
                behalf, for the purposes of storage.
              </span>
            </p>
          </div>

          {/* P13 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">DATA BREACH:</h2>

            <p>
              <span>
                In the event we become aware that the security of the Site, or
                our Services has been compromised or your Personal Information
                has been disclosed to unrelated Third-Parties as a result of
                external activity, including, but not limited to, security
                attacks or fraud, we reserve the right to take reasonably
                appropriate measures, including, but not limited to,
                investigation and reporting, as well as notification to and
                cooperation with law enforcement authorities. In the event of a
                data breach, we will make reasonable efforts to notify affected
                individuals if we believe that there is a reasonable risk of
                harm to the user as a result of the breach or if notice is
                otherwise required by law. When we do, we will post a notice on
                the Website, and where required, send you an email. If for any
                reason, you have a reason to believe that your Information
                shared with us is no longer secure, you may contact us via our
                e-mail address at legal@mindginie.com.
              </span>
            </p>
          </div>

          {/* P14 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">ELIGIBILITY:</h2>

            <p>
              <span>
                <span className="privacy_block">
                  The Services are not intended for users under the age of 18
                  years (“
                  <span className="privacy_block font-semibold">
                    Permissible Age
                  </span>
                  ”). We will not be responsible for any consequence that arises
                  as a result of misuse of any kind of our Services that may
                  occur by virtue of any person including a minor accessing and
                  using the Services. By using the Services, you warrant that
                  you are of the Permissible Age and thus eligible to access,
                  register for, and use the Services. We reserve the right to
                  refuse to provide you with access to the Services if it is
                  discovered that you are under the Permissible Age. You
                  acknowledge that the Company does not have the responsibility
                  to ensure that you conform to the aforesaid eligibility
                  criteria. It shall be your sole responsibility to ensure that
                  you meet the required qualification.
                </span>
              </span>
            </p>
          </div>

          {/* P15 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">COOKIES:</h2>

            <p>
              <span>
                We may place Cookies on your personal computer. "Cookies" are
                small identifiers sent from a website server and stored on your
                computer’s hard drive, similar to a licence plate, that help us
                to recognize you if you visit our Site again. Also, our Site
                uses cookies to track how you found our Site thereby helping us
                improve your experience, understand how our services are being
                used, and customise our Services. Cookies are not designed to be
                used to get Personal Information from your hard drive, your
                e-mail or any other Personal Information about you. To protect
                your privacy, we do not use Cookies to store or transmit any
                Information about you on the Internet. You can reject Cookies by
                changing your browser settings, but please be aware that if you
                reject our Cookies it is possible that our Site may not properly
                load, your access to certain features of the Services, or
                content or information offered as part of the Site, might be
                denied. We may let other companies use Cookies, web beacons and
                similar tracking technologies on our Services. These companies
                may collect information about how you use our Services and other
                websites and online services over time and across different
                services. This information may be used to, among other things,
                analyse and track data, determine the popularity of certain
                content and better understand your online activity.
              </span>
            </p>
          </div>

          {/* P16 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">SECURITY:</h2>

            <p>
              <span>
                Reasonable security measures are in place to help protect
                against the loss, misuse and alteration of the Information under
                our control. We promote safety and security on and off our
                Services, such as by investigating suspicious activity or
                violations of our Privacy Policy and Terms of Service and to
                ensure our Services are being used legally. If we do detect
                something risky that would compromise your privacy and security,
                we shall notify you by updating our respective policies and help
                guide you through steps to stay better protected. We shall take
                complete measures to safeguard you and our Services from
                unauthorised access, alteration, disclosure, or destruction of
                Information we hold, including: Use of encryption to keep your
                Information private while in transit & Timely review of our
                Information collection, storage, and processing practices,
                including physical security measures, to prevent unauthorised
                access to our systems.
              </span>
            </p>
          </div>

          {/* P17 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              ASSIGNMENT AND TRANSFER:
            </h2>

            <p>
              <span>
                All our rights and obligations under our Privacy Policy and
                Terms of Service are freely assignable by us to any of our
                affiliates, in connection with a merger, acquisition,
                restructuring, liquidation, bankruptcy or sale of assets, or by
                operation of law or otherwise, and we may transfer your
                Information to any of our affiliates, successor entities, or new
                owner.
              </span>
            </p>
          </div>

          {/* P18 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              LAW AND PROTECTION:
            </h2>

            <p>
              <span>
                Only Indian courts shall have jurisdiction in connection to and
                the usage of the Services. We may collect, use, preserve, and
                share your Information if we have enough cause to believe that
                it is reasonably necessary to: Respond pursuant to applicable
                Indian laws or regulations, to legal process, or to government
                requests; Enforce our Privacy Policy, Terms of Service and any
                other applicable terms and policies, including for
                investigations of potential violations; Detect, investigate,
                prevent, and address fraud and other illegal activities,
                security, or technical issues; or Protect the rights, property,
                and safety of our users, our Company, its affiliates, or others.
              </span>
            </p>
          </div>

          {/* P19 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">
              MODIFICATIONS TO THIS POLICY:
            </h2>

            <p>
              <span>
                We may modify the Privacy Policy from time to time at our sole
                discretion. If we do so, we will revise the updated date at the
                bottom of this Privacy Policy. It’s important that you review
                the Privacy Policy from time to time because your continued use
                of our Services after the effective date of any change to the
                Privacy Policy will be deemed to be your acceptance to the
                modified Privacy Policy. If you don’t agree to be bound by the
                modified Privacy Policy, then you must not use the Services
                anymore.
              </span>

              <span>
                If we make material changes to our Privacy Policy, we may notify
                you by posting a modified notice on the Site or by directly
                sending you a notification.
              </span>
            </p>
          </div>

          {/* P20 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">SEVERABILITY:</h2>

            <p>
              <span>
                If any term or condition of this Privacy Policy is deemed
                invalid or unenforceable by a court of law with binding
                authority, then the remaining terms and conditions shall not be
                affected, and the court shall reform the invalidated or
                unenforceable term or condition to the maximum extent permitted
                under the law and consistent with the intent of this Privacy
                Policy.
              </span>
            </p>
          </div>

          {/* P21 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">ACCEPTANCE:</h2>

            <p>
              <span>
                You acknowledge that you have read this Privacy Policy and agree
                to all its terms and conditions. By using the Site or Services
                you agree to be bound by this Privacy Policy. If you do not
                agree to abide by the terms of this Privacy Policy, you are not
                authorised to use or access the Site and Services.
              </span>
            </p>
          </div>

          {/* P22 */}
          <div className="privacy_policy_para_container">
            <h2 className="privacy_heading_second uppercase">CONTACT US:</h2>

            <p>
              <span>
                <span className="privacy_block">
                  If you have any questions about this Privacy Policy, our
                  practices or your dealings with us, you may contact Jessica
                  Dawda, our Grievance Officer at{" "}
                  <a className="privacy_url_link" href="mailto:MG@mindginie.in">
                    MG@mindginie.in
                  </a>{" "}
                </span>
              </span>

              <span>
                This Privacy Policy is effective and was last updated on 1st of
                July 2023 Seiaum Tech Private Limited is located at Plot No. 4,
                4th Floor, Monarch Towers, Sector 44, Gurgaon, Haryana, India.
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
