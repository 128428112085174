import React from "react";
import { Link } from "react-router-dom";

import "../../css/BlogStyles/Blogs.css";
import blog_1 from "../../img/blogs/blog_1.jpeg";

const Blogs = () => {
  return (
    <>
      <section id="blogs" className="section_blogs">
        <div className="blogs_container_div">
          <div className="blogs_grid_div">
            <Link
              to="/blogs/psychological-stress-vs-physiological-stress"
              className="blog_box"
            >
              <div className="blog_img_box">
                <img className="blog_img" src={blog_1} alt="Blog One" />
              </div>

              <div className="blog_text_box">
                <h2>Psychological Stress Vs Physiological Stress</h2>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blogs;
