import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// For Personalise.js —
import "../../css/HomeStyles/Personalise.css";
import img1 from "../../img/home/personalise/tile-1.png";
import img2 from "../../img/home/personalise/tile-2.png";
import img3 from "../../img/home/personalise/tile-3.png";
import arrow_right from "../../img/svg/arrow-right.svg";

// For TrialForm —
import "../../css/HomeStyles/TrialForm.css";

const Personalise = () => {
  // For Personalise.js —
  const [showTrialForm, setShowTrialForm] = useState(false);
  const setTrialForm = () => {
    setShowTrialForm(true);
  };

  //For Trial Form —
  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/");
  };

  const handleTrialForm = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await axios.post(
        "https://apis.mindginie.com/accounts/pre-register/",
        {
          email,
        }
      );

      if (res.data.status === true) {
        // console.log(res.data);

        let setHeading = setInterval(() => {
          handleNavigate();
          clearInterval(setHeading);
        }, 8000);
      } else {
        console.log("This email has already been registered!");
      }
    } catch (e) {
      console.log(e);
    }

    setShowTrialForm(false);
    setEmail("");
  };

  return (
    <>
      <section className="section_personalise">
        <div className="container">
          <div className="main-container_prsn">
            {/* START */}
            <div className="prim-heading-div_prsn">
              <h2 className="second_heading">
                MindGinie - Your AI-powered friend
              </h2>
            </div>

            <div className="second-text-div_prsn">
              <p className="primary_text_24px lato_font_style font-medium">
                You can talk to MindGinie whenever you’re having a stressful
                moment,{" "}
              </p>
              <p className="primary_text_24px lato_font_style font-medium">
                to relax quickly and cope with the situation. Coming soon.
              </p>
            </div>

            <div className="main-tile-container_prsn">
              <div className="tile-container_prsn">
                {/* #1 */}
                <div className="tile-box_prsn tile-one_prsn">
                  <div className="tile-box-A_prsn">
                    <img src={img1} alt="Personalised AI" />
                  </div>

                  <div className="tile-box-B_prsn">
                    <h3 className="fourth_heading">
                      Personalised Wellness Support with the Power of AI
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      MindGinie analyses your stress patterns, triggers and
                      symptoms to analyse and predict stressful situations, and
                      helps you out with personalised mitigation techniques and
                      guidance in your down moments.
                    </p>
                  </div>
                </div>

                {/* #2 */}
                <div className="tile-box_prsn tile-two_prsn">
                  <div className="tile-box-A_prsn">
                    <img src={img2} alt="Personalised Chat" />
                  </div>

                  <div className="tile-box-B_prsn">
                    <h3 className="fourth_heading">
                      An Empathetic Friend Who’s Always There For You.
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      Treat MindGinie like a friend who knows you - who’s always
                      there to alk to you and support you during your stressful
                      moments, just like a real friend.
                    </p>
                  </div>
                </div>

                {/* #3 */}
                <div className="tile-box_prsn tile-three_prsn">
                  <div className="tile-box-A_prsn">
                    <img src={img3} alt="Secure Data" />
                  </div>

                  <div className="tile-box-B_prsn">
                    <h3 className="fourth_heading">
                      We Care About Your Privacy.
                    </h3>
                    <p className="tertiary_text lato_font_style">
                      We know how important your privacy is. We never store your
                      personal information and chat conversations outside your
                      phone, so as to maximise the privacy and security of your
                      data.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="cta_prsn">
              {!showTrialForm && (
                <a
                  className="circular_btns"
                  href="#trialForm"
                  onClick={setTrialForm}
                >
                  Join the Waitlist{" "}
                  <img src={arrow_right} alt="Join the Waitlist" />
                </a>
              )}

              {showTrialForm && (
                <form onSubmit={handleSubmit} className="email_inp_div_trl_frm">
                  <input
                    value={email}
                    onChange={handleTrialForm}
                    type="email"
                    className="email_input second_text poppins_font_style"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                    aria-describedby="email"
                    required
                  />

                  <div className="trial_form_submit_btn">
                    <button
                      id="hero_section_email_btn"
                      className="form_btn_trl_frm second_text"
                      type="submit"
                    >
                      Yes, I’m in
                    </button>
                  </div>
                </form>
              )}
            </div>

            {/* END */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Personalise;
